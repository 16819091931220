import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Core team located in Silicon Valley`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`Our team is a successful software development company with all the ingredients in it. We’ve recruited powerful software developers, architects and testers, with highly talented people. Analyzes the nuances of our customers, the methodical managers and developers of projects, the driven management with an emphasis on quality and delivery and innovators that modify our view.`}</p>
    </PageDescription>
    <Row className="example-section" mdxType="Row">
      <Column mdxType="Column">
        <br />
        <img {...{
          "src": "/images/execs.svg",
          "alt": "Executives"
        }}></img>
      </Column>
    </Row>
    <Row className="example-section" mdxType="Row">
      <Column colMd={2} colLg={4} mdxType="Column">
        <h2>{`Strategic Locations`}</h2>
        <br />
        <Aside className="example-aside" mdxType="Aside">
          <p><strong parentName="p">{`US`}</strong><br /></p>
          <p>{`headquarter, management, business development`}</p>
          <br />
        </Aside>
        <Aside className="example-aside" mdxType="Aside">
          <p><strong parentName="p">{`Latin America`}</strong><br /></p>
          <p>{`development center`}</p>
          <br />
        </Aside>
        <Aside className="example-aside" mdxType="Aside">
          <p><strong parentName="p">{`Eastern Europe`}</strong><br /></p>
          <p>{`development center, management, sales offices`}</p>
          <br />
        </Aside>
        <Aside className="example-aside" mdxType="Aside">
          <p><strong parentName="p">{`Asia`}</strong><br /></p>
          <p>{`development center`}</p>
          <br />
        </Aside>
      </Column>
      <Column colMd={6} colLg={8} mdxType="Column">
        <img {...{
          "src": "/images/map.svg",
          "alt": "core blue 100"
        }}></img>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      